import React from 'react';
import './ListView.scss';

const ListView = ({ title = 'test', list }) => {
    return (
        <div className="list-view">
            <p>{ title }</p>
            <ul>
                { list.map(function(item, i){ return <li key={i}>{ item }</li> }) }
            </ul>
        </div>
    );
};

export default ListView;