import React, { useState } from 'react';
import './SideMenu.scss';

import close_menu from '../../images/close_menu.png';

const SideMenu = ({ menuList }) => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        <div>
            <div className="burger-menu" onClick={() => { setOpenMenu(true)}}>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className={openMenu ? 'side-menu open' :'side-menu'}>
            <ul className={openMenu ? 'open' :'closed'} onClick={() => { setOpenMenu(false) } }>
                <li className="close-menu" onClick={() => { setOpenMenu(false) } }><img alt="closeMenu" src={close_menu}/></li>
                {
                        menuList.map(function(item, i) {
                            return <li key={i}> <a href={item['link']} onClick={() => { setOpenMenu(false) } }> {item['name']} </a> </li>
                        })
                }
            </ul>
        </div>
        </div>
    );
};

export default SideMenu;