import React, { useState, useEffect } from "react";
import { post } from "axios";
import "./ContactForm.scss";
import FormValidator from "./FormValidator.js";
import arrow_down from "../../images/arrow_down.png";

//animation on scroll
import VisibilitySensor from "react-visibility-sensor";

const ContactForm = ({ recruit = false, formType, textAreaRows }) => {
  const [recruitFields, setRecruitFields] = useState({
    role: "",
    message: "",
    name: "",
    email: "",
    file: "",
  });
  const [contactFields, setContactFields] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [recruitSubmit, setRecruitSubmit] = useState(false);
  const [contactSubmit, setContactSubmit] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  // State to control input alert messages
  const [inputAlerts, setInputAlerts] = useState({});

  // Set input file label name
  const [inputLabel, setinputLabel] = useState("Curriculum Vitae");

  const rolesArray = [
    "designer",
    "marketeer",
    "content writter",
    "back end developer",
    "front end developer",
    "account manager",
  ];

  // Changel input file label
  const labelChangeHandler = (e) => {
    if (e.target.files["length"] > 0)
      setinputLabel(`${e.target.files[0].name}`);
    setRecruitFields({ ...recruitFields, file: e.target.files[0] });
    setInputAlerts({ ...inputAlerts, file: false });
    setFormError(false);
  };

  const submitForm = (fieldsHelper, fields, url) => {
    let verifyForm = new FormValidator(fieldsHelper);
    let subject = "EVOKE IT - Formulário de contacto";

    switch (verifyForm.output.status) {
      case true:
        const postUrl = url,
          formData = new FormData();

        for (var key in fields) {
          formData.append(key, fields[key]);
        }
        formType === "recruit"
          ? (subject = "EVOKE IT - Formulário de cadidatura")
          : (subject = "EVOKE IT - Formulário de contacto");
        formData.append("subject", subject);
        formData.append("formtype", formType);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        formType === "recruit"
          ? setRecruitSubmit(true)
          : setContactSubmit(true);

        post(postUrl, formData, config)
          .then((response) => {
            //console.log(response);
            setFormSuccess(true);
            setTimeout(function () {
              setFormSuccess(false);
            }, 4000);
          })
          .catch((error) => {
            //console.log(error);
            setFormError(true);
            setTimeout(function () {
              setFormError(false);
            }, 4000);
          });
        break;

      case false:
        setInputAlerts(verifyForm.output);
        break;
      default:
        break;
    }
  };

  const handleSubmitEvent = (e) => {
    e.preventDefault();

    // Recruit Contact Form
    if (formType === "recruit" && !recruitSubmit) {
      // Creating helper Array to keep original array as it is
      let formFields = { ...recruitFields };

      formFields.role = {
        value: formFields.role,
        type: "text",
        required: true,
      };
      formFields.message = {
        value: formFields.message,
        type: "textarea",
        minLength: 10,
        maxLength: 255,
        required: true,
      };
      formFields.name = {
        value: formFields.name,
        type: "text",
        minLength: 3,
        maxLength: 30,
        required: true,
      };
      formFields.email = {
        value: formFields.email,
        type: "text",
        maxLength: 50,
        required: true,
      };
      formFields.file = {
        value: formFields.file,
        type: "file",
        required: true,
        fileExtensions: ["pdf", "jpg", "png"],
      };

      submitForm(formFields, recruitFields, "./api/");
    } else {
      if (formType === "contact" && !contactSubmit) {
        let formFields = { ...contactFields };

        formFields.name = {
          value: formFields.name,
          type: "text",
          maxLength: 30,
          minLength: 3,
          required: true,
        };
        formFields.email = {
          value: formFields.email,
          type: "text",
          maxLength: 50,
          required: true,
        };
        formFields.message = {
          value: formFields.message,
          type: "textarea",
          minLength: 5,
          maxLength: 255,
          required: true,
        };

        submitForm(formFields, contactFields, "./api/");
      }
    }
  };

  const renderBlock = () => {
    // Jsx for recruit form
    if (recruit) {
      return (
        <div className="d-inline">
          <input
            className="input-roles"
            list="roles"
            name="roles"
            maxLength="50"
            value={recruitFields.role}
            onChange={(event) => {
              setRecruitFields({
                ...recruitFields,
                role: event.target.value,
              });
              setInputAlerts({ ...inputAlerts, role: false });
              setFormError(false);
              setFormSuccess(false);
            }}
          />
          <datalist id="roles">
            {rolesArray.map(function (role, i) {
              return <option key={i} value={role}></option>;
            })}
          </datalist>
          <h3 className="d-inline"> e gostaria de trabalhar convosco porque</h3>
          {inputAlerts.role ? (
            <p className="invalid-field">{inputAlerts.role}</p>
          ) : (
            ""
          )}
        </div>
      );
    }

    // Jsx for contact form
    else {
      return (
        <div className="name-email-boxes">
          <div className="name-box">
            <input
              className={
                inputAlerts.name
                  ? "d-block input-name input-alert-true mb-3"
                  : "d-block input-name"
              }
              type="text"
              placeholder="Nome"
              value={contactFields.name}
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  name: event.target.value,
                });
                setInputAlerts({ ...inputAlerts, name: false });
                setFormError(false);
                setFormSuccess(false);
              }}
            ></input>
            {inputAlerts.name ? (
              <p className="invalid-field">{inputAlerts.name}</p>
            ) : (
              ""
            )}
          </div>
          <div className="email-box">
            <input
              className={
                inputAlerts.email
                  ? "d-block input-name input-alert-true mb-3"
                  : "d-block input-name mb-3"
              }
              type="text"
              placeholder="Email"
              value={contactFields.email}
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  email: event.target.value,
                });
                setInputAlerts({ ...inputAlerts, email: false });
                setFormError(false);
                setFormSuccess(false);
              }}
            ></input>
            {inputAlerts.email ? (
              <p className="invalid-field">{inputAlerts.email}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setRecruitFields({ role: "", message: "", name: "", email: "", file: "" });
    setinputLabel("Curriculum Vitae");
    setRecruitSubmit(false);
  }, [recruitSubmit]);

  useEffect(() => {
    setContactFields({ name: "", email: "", message: "" });
    setContactSubmit(false);
  }, [contactSubmit]);

  // Render page
  return (
    <VisibilitySensor offset={{ top: -2000 }} partialVisibility={true}>
      {({ isVisible }) => (
        <div
          className={`contact-form d-flex ${
            isVisible ? "fadeIn enter" : "fadeIn"
          }`}
        >
          <form
            className={recruit ? "" : "formContact"}
            encType="multipart/form-data"
            method="POST"
          >
            <h3 className="d-inline">
              {recruit ? "Eu sou" : "Quero ser contactado"}{" "}
            </h3>

            {renderBlock()}

            <textarea
              maxLength="255"
              rows={textAreaRows}
              className={
                inputAlerts.message
                  ? "d-block input-name input-alert-true mb-3"
                  : "d-block input-name w-100"
              }
              placeholder={recruit ? "Motivo" : "Assunto"}
              value={recruit ? recruitFields.message : contactFields.message}
              onChange={(event) => {
                recruit
                  ? setRecruitFields({
                      ...recruitFields,
                      message: event.target.value,
                    })
                  : setContactFields({
                      ...contactFields,
                      message: event.target.value,
                    });
                setInputAlerts({ ...inputAlerts, message: false });
                setFormError(false);
                setFormSuccess(false);
              }}
            ></textarea>
            {inputAlerts.message ? (
              <p className="invalid-field">{inputAlerts.message}</p>
            ) : (
              ""
            )}

            {recruit ? (
              <div className="formContact">
                <div className="name-email-boxes">
                  <div className="name-box">
                    <input
                      className={
                        inputAlerts.name
                          ? "d-block input-name input-alert-true  mb-3"
                          : "d-block input-name"
                      }
                      type="text"
                      placeholder="Nome"
                      value={recruitFields.name}
                      onChange={(event) => {
                        setRecruitFields({
                          ...recruitFields,
                          name: event.target.value,
                        });
                        setInputAlerts({ ...inputAlerts, name: false });
                        setFormError(false);
                        setFormSuccess(false);
                      }}
                    ></input>
                    {inputAlerts.name ? (
                      <p className="invalid-field">{inputAlerts.name}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="email-box">
                    <input
                      className={
                        inputAlerts.email
                          ? "d-block input-name input-alert-true mb-3"
                          : "d-block input-name mb-3"
                      }
                      type="text"
                      placeholder="Email"
                      value={recruitFields.email}
                      onChange={(event) => {
                        setRecruitFields({
                          ...recruitFields,
                          email: event.target.value,
                        });
                        setInputAlerts({ ...inputAlerts, email: false });
                        setFormError(false);
                        setFormSuccess(false);
                      }}
                    ></input>
                    {inputAlerts.email ? (
                      <p className="invalid-field">{inputAlerts.email}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <input
                  id="f02"
                  type="file"
                  accept=".pdf, .jpg, .png"
                  name="attachment"
                  placeholder="Curriculum Vitae"
                  onChange={labelChangeHandler}
                  onClick={(event) => {
                    console.log(event.target.files);
                    event.target.value = "";
                    event.target.file = "";
                    setinputLabel("Curriculum Vitae");
                  }}
                />
                <label className="mt-5" htmlFor="f02">
                  {inputLabel}
                  <img className="pl-2" alt="label arrow" src={arrow_down} />
                </label>
                {inputAlerts.file ? (
                  <p className="invalid-field">{inputAlerts.file}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div className="text-center">
              {formError ? (
                <div className="form-error">
                  Ocorreu um erro no envio do formulário,
                  <br /> por favor tente novamente.
                </div>
              ) : (
                ""
              )}
              {formSuccess ? (
                <div className="form-suce">
                  Obrigado, o seu formulário foi enviado.
                </div>
              ) : (
                ""
              )}

              <input
                type="submit"
                value="Enviar"
                className="mt-5 form-submit"
                onClick={handleSubmitEvent}
              ></input>
            </div>
          </form>
        </div>
      )}
    </VisibilitySensor>
  );
};

export default ContactForm;
