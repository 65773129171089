class FormValidator {
  constructor(formFields, minLength = 0, maxLength = 255) {
    let validateEmail =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let output = { status: true };
    let extension;

    Object.keys(formFields).map(function (key) {
      if (formFields[key].minLength) {
        minLength = formFields[key].minLength;
        if (formFields[key].value.length < minLength) {
          output[key] = `Mínimo de caracteres: ${formFields[key].minLength}`;
          output["status"] = false;
        }
      }

      if (formFields[key].maxLength) {
        maxLength = formFields[key].maxLength;
        if (formFields[key].value.length > maxLength) {
          output[key] = "Excedeu o limite máximo de caracteres";
          output["status"] = false;
        }
      }

      if (formFields[key].value === undefined) {
        output[key] = "Campo inválido";
        output["status"] = false;
      }

      if (!formFields[key].required) {
      } else {
        if (formFields[key].value === "") {
          output[key] = "Campo obrigatório";
          output["status"] = false;
        }
      }

      if (key === "email") {
        if (!validateEmail.test(formFields[key].value)) {
          output[key] = "Email inválido";
          output["status"] = false;
        }
      }

      if (key === "file") {
        if (
          formFields[key].value !== "" &&
          formFields[key].value !== undefined
        ) {
          let flag = false;
          formFields[key].fileExtensions.map(function (ext, i) {
            extension = formFields[key].value.type.split("/")[1];
            if (extension === ext) flag = true;
          });

          if (flag === false) {
            output[key] = "Ficheiro inválido";
            output["status"] = false;
          }
        } else {
          output[key] = "Ficheiro inválido";
          output["status"] = false;
        }
      }
    });
    return { output, formFields };
  }
}

export default FormValidator;
