import React from 'react';
//animation on scroll package
import VisibilitySensor from "react-visibility-sensor";
const GridList = ({ images }) => {

    const divide = Math.ceil(images.length / 5);
    const opt = [];
    const imagesHelper = images;
    for (var i = 0; i < divide; i++) {
        const helper = imagesHelper.splice(0,5);
        helper.map(function (image, index) {
            return (
                opt.push(
                    <VisibilitySensor>
                    {({ isVisible }) => (
                        <div className={`col-lg-2 col-md-3 col-sm-4 col-4 text-center brand-logo ${isVisible ? "fadeIn enter" : "fadeIn"}`}>
                            <img className="w-100" src={image} alt="" />
                        </div>
                    )}
                    </VisibilitySensor>
                )
            )
        })
    }

    return(
        <div className="row justify-content-center p-1 m-0 w-100">{opt}</div>
    );
};

export default GridList;