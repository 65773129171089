const ScrollDetection = () => {
    let sectionArr = document.querySelectorAll('.section');
    let scrollElement = document.getElementById('scrollmouse');
    let bottomPage = document.getElementById('contacts');
    let scrollID = 'companies';

    const getOffsetTop = function(element) {
        if (!element) return 0;
        return getOffsetTop(element.offsetParent) + element.offsetTop;
    };

    window.onscroll = function (e) {
        console.clear();
        // console.log(`scrollY: ${window.scrollY}`)
        // console.log(`offsetTop: ${bottomPage.offsetTop}`)
        if (window.scrollY  + window.innerHeight >= getOffsetTop(bottomPage)) {
            scrollElement.style.opacity = 0;
            scrollElement.style.transition = "all 1s";
            scrollElement.style.display = 'none';
        } else {
            scrollElement.style.opacity = 1;
            scrollElement.style.transition = "all 1s";
            scrollElement.style.display = 'block';
        }
        sectionArr.forEach(element => {
            let topDistance = getOffsetTop(element);
            console.log(`${element.id}: ${window.scrollY + window.innerHeight >= topDistance - 62.5}`);

            if (
                (window.scrollY + window.innerHeight >= topDistance - 200)
                    // && (window.scrollY) <= (element.offsetTop + element.offsetHeight)
            ) {
                // console.log(element)
                // console.log(`Mudou o scrollID para ${element.id}`)
                // console.log(`scrollY: ${window.scrollY}`)
                // console.log(`offsetTop: ${element.offsetTop}`)
                // console.log(`offsetHeight: ${element.offsetHeight}`)
                // console.log(`element.offsetTop - (element.offsetHeight + (window.innerHeight * 0.5)): ${element.offsetTop - (element.offsetHeight + (window.innerHeight * 0.4))}`)
                if (element.id == "contactus" && window.innerWidth > 1224) 
                    scrollID = "contacts";
                else
                    scrollID = element.id;
            }
        });

        // console.log(scrollID)
    }

    scrollElement.addEventListener('pointerdown', ()=> {
        if(scrollID !== null && scrollID !== 'undefined' && scrollID !== '') {
            // console.log(`scrollID: ${scrollID}`)
            let scroll = document.getElementById(scrollID);
            scroll.scrollIntoView({behavior: "smooth"});
        }
    });

    scrollElement.addEventListener('transitionend', () => {
        if(scrollElement.style.display === 'none') {
            scrollElement.style.display = 'block';
        }

        if(scrollElement.style.display === 'block') {
            scrollElement.style.display = 'none';
        }
    })
}

export default ScrollDetection;