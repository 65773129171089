import React, { useEffect } from "react";

//Styles
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";

//Images
import scrollMouse from "./images/scroll_mouse.png";
import owlbg from "./images/company_owl.png";
import owlogo from "./images/owl_logo.png";
import evkbg from "./images/company_evk.png";
import evklogo from "./images/evk_logo.png";
import evokelogo from "./images/evokeit_logo.png";
import bnpbg from "./images/company_bnp.png";
import bnplogo from "./images/bnp_logo.png";
import RecruitBg from "./images/entrada3_gray.png";
import ContactsBg from "./images/contacts_bg.png";
import Return from "./images/return.png";

//Components
import IntroSection from "./components/IntroSection/IntroSection.js";
import CompaniesSection from "./components/CompaniesSection/CompaniesSection.js";
import Section from "./components/Section/Section.js";
import BrandsSection from "./components/BrandsSection/BrandsSection.js";
import ContactForm from "./components/ContactForm/ContactForm.js";
import SideMenu from "./components/SideMenu/SideMenu.js";
import ScrollDetection from "./components/ScrollDetection/ScrollDetection.js";

const App = () => {
  const menuLinkList = [
    { name: "HOME", link: "#" },
    { name: "OWL CREATIVE", link: "#owl" },
    { name: "EVOKE IT", link: "#evk" },
    { name: "BRAND N' PLAY", link: "#bnp" },
    { name: "AWESOME BRANDS", link: "#brands" },
    { name: "JOIN OUR TEAM", link: "#recruit-form" },
    { name: "CONTACTS", link: "#contactus" },
  ];

  useEffect(() => {
    ScrollDetection();
  });
  return (
    <div className="App">
      <div id="scrollmouse">
        {/* <img alt="Mouse scroll" src={scrollMouse} /> */}
        <svg height="80" width="50">
          <rect
            x="15"
            y="10"
            rx="10"
            ry="10"
            width="20"
            height="35"
            className="svg-scroll"
          />
          <rect x="23.5" y="15" rx="2.5" ry="2.5" width="3" height="6" />
          <polygon
            points="15,53 15,60 25,68 35,60 35,53 25,61"
            className="svg-scroll"
          />
        </svg>
      </div>
      <SideMenu menuList={menuLinkList} />
      <IntroSection scrollLink="#companies" />
      <CompaniesSection />
      <Section
        bgImage={owlbg}
        alt="Owl - Creative Studio Logo"
        logo={owlogo}
        title="Estúdio de Design e Comunicação em constante processo Criativo, para Desenvolver e Comunicar as marcas e produtos dos nossos clientes."
        list={[
          "Design Gráfico",
          "Consultoria de Comunicação",
          "Content Marketing",
          "Marketing Digital",
          "Branding",
          "Fotografia e Vídeo",
          "Online",
        ]}
        sectionId="owl"
        visitLink="https://owlcreative.pt/"
        scrollLink="#evk"
      />
      <Section
        bgImage={evkbg}
        logo={evokelogo}
        title="Estúdio de inovação e tecnologia que Desenvolve Experiências para Envolver, Surpreender e Conectar as marcas, a cultura e o seu público-alvo."
        list={[
          "Museus",
          "Ativação de Marca",
          "Feiras",
          "Eventos",
          "Pontos de venda",
          "Showroom",
        ]}
        sectionId="evk"
        visitLink="https://www.evoke.pt/"
        scrollLink="#bnp"
        direction="left"
      />

      <Section
        bgImage={bnpbg}
        logo={bnplogo}
        title="Fábrica de entretenimento, disponibiliza Aluguer de Soluções de Gamificação e Comunicação que Potencializam a interação nos seus eventos."
        list={[
          "Eventos",
          "Congressos",
          "Roadshows",
          "Festivais",
          "Pontos de venda",
        ]}
        sectionId="bnp"
        visitLink="https://www.brandnplay.com/"
        scrollLink="#brands"
      />

      <BrandsSection sectionId="brands" scrollLink="#recruit-form" />

      <div className="section recruit-section" id="recruit-form">
        <div
          className="container recruit-wrapper"
          style={{ backgroundImage: `url(${RecruitBg})` }}
        >
          <div className="top-fader"></div>
          <div className="row w-100 content-wrapper ml-auto mr-auto min-vh-100 flex-column">
            <h2 className="text-center ml-auto mr-auto">JOIN OUR TEAM</h2>
            <h3 className="text-center ml-auto mr-auto">
              Are you ready for a challenge?
            </h3>

            <ContactForm recruit={true} formType="recruit" textAreaRows="4" />
          </div>
          <div className="bot-fader"></div>
        </div>
      </div>

      <div className="section contacts-section" id="contactus">
        <div
          className="container recruit-wrapper"
          style={{ backgroundImage: `url(${ContactsBg})`, minHeight: "80vh" }}
        >
          <div className="top-fader"></div>
          <div
            className="row w-100 content-wrapper ml-auto mr-auto flex-column"
            style={{ minHeight: "80vh" }}
          >
            <h2
              className="text-center ml-auto mr-auto"
              style={{ marginBottom: "50px" }}
            >
              CONTACTS
            </h2>
            <ContactForm formType="contact" textAreaRows="4" />
          </div>

          {/* Contacts Footer*/}
          <div className="footer-contacts pt-5" id="contacts">
            <div className="row pb-3 m-0 w-100">
              <div className="col-lg-3 col-md-12 col-sm-12 p-0 footer-logo mb-3">
                <img
                  style={{ width: "auto", maxWidth: "240px" }}
                  src={evklogo}
                  alt="EVK logo"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 mb-3">
                <h4>Aveiro</h4>
                <p>
                  TABpark - Zona Industrial da Taboeira <br></br> Lote 27,
                  Fração H1 <br></br> 3800 - 055 Aveiro
                </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 mb-3">
                <h4>Contacto</h4>
                <p>234 009 539</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="back-to-top">
        <a href="#">
          <img src={Return} alt="return" />
        </a>
      </div>
    </div>
  );
};

export default App;
