import React from 'react';
import './CompaniesSection.scss';

const CompaniesSection = () => {
    return (
        <div className="companies-section">
            <div className="section container d-flex p-0" id="companies">
                <div className="row w-100 p-0 m-0" id="companies-wrapper" style={{height: "100vh"}}>
                    <a href="#owl" className="col-xl-4 col-lg-12 col-md-6 col-xs-12 text-center m-0 p-0 company-strip">
                        <div className="owl-bgimage w-100 h-100"></div>
                        <div className="companies-title">
                            <h2>OWL</h2>
                            <p>Creative Studio</p>
                        </div>
                    </a>
                    <a href="#evk" className="col-xl-4 col-lg-12 col-md-6 col-xs-12 text-center m-0 p-0 company-strip">
                        <div className="evk-bgimage w-100 h-100"></div>
                        <div className="companies-title">
                            <h2>EVOKE IT</h2>
                            <p>Innovation Studio</p>
                        </div>
                    </a>
                    <a href="#bnp" className="col-xl-4 col-lg-12 col-md-12 col-xs-12 text-center m-0 p-0 company-strip">
                        <div className="bnp-bgimage w-100 h-100"></div>
                        <div className="companies-title">
                            <h2>Brand n' Play</h2>
                            <p>Amusement Factory</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CompaniesSection;