import React from 'react';
import './BrandsSection.scss';

import GridList from '../GridList/GridList.js';

//Import Images
import superbock from '../../images/brands/superbock.png';
import adidas from '../../images/brands/Adidas.png';
import cofidis from '../../images/brands/Cofidis.png';
import continente from '../../images/brands/continente.png';
import deltaq from '../../images/brands/Delta Q.png';
import doritos from '../../images/brands/Doritos.png';
import fidelidade from '../../images/brands/Fidelidade.png';
import fnac from '../../images/brands/Fnac.png';
import lusiadas from '../../images/brands/Lusiadas.png';
import meo from '../../images/brands/MEO.png';
import nespresso from '../../images/brands/Nespresso.png';
import radiocomercial from '../../images/brands/radio comercial.png';
import teka from '../../images/brands/Teka.png';
import toyota from '../../images/brands/Toyota.png';
import ucal from '../../images/brands/Ucal.png';
import gocontact from '../../images/brands/gocontact.png';
import wavecom from '../../images/brands/wavecom.png';
import marthas from '../../images/brands/marthas.png';

const BrandsSection = ({sectionId, scrollLink }) => {

    const brandImages = [superbock, adidas, cofidis, continente, deltaq, doritos, fidelidade, fnac, lusiadas, meo, nespresso, radiocomercial, teka, toyota, ucal, gocontact, wavecom, marthas]

    return (
        <div className="section brandssection pt-5 w-100" id={sectionId}>
            <div className="container d-flex h-100">
                <div className="row d-flex w-100 ml-auto mr-auto text-center">
                    <div className="mx-auto">
                        <h2>AWESOME BRANDS</h2>
                        <p>Some brands we've worked with</p>
                    </div>
                    <div className="row grid-list">
                        <GridList images = { brandImages } />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default BrandsSection;