import React from "react";
import "./IntroSection.scss";

//animation on scroll package
import VisibilitySensor from "react-visibility-sensor";

const IntroSection = ({ scrollLink }) => {
  return (
    <div className="intro-section">
      <div className="container d-flex" id="company-intro">
        <div className="row d-flex w-100 content-wrapper">
          <div className="menu-logo">
            <img
              alt="Evoke It logo"
              src={require("../../images/evk_logo.png")}
            />
          </div>
          <VisibilitySensor partialVisibility={true} offset={{ top: -400 }}>
            {({ isVisible }) => (
              <div
                className={`company-message ${
                  isVisible ? "fadeIn enter" : "fadeIn"
                }`}
              >
                <h2>
                  Olá,<br></br>somos a EVK
                </h2>
                <p className="col-lg-6 col-sm-12">
                  Trabalhamos de forma integrada, permitindo aos nossos clientes
                  ter uma resposta a 360º às suas necessidades de comunicação.
                </p>
              </div>
            )}
          </VisibilitySensor>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
