import React from 'react';
import './Section.scss';
import "animate.css/animate.min.css";
//Components
import ListView from '../ListView/ListView.js';

//animation on scroll
import VisibilitySensor from "react-visibility-sensor";

const CompaniesSection = ({ bgImage, alt, logo, list, title, visitLink, sectionId, subTitle, direction="right" }) => {
    return (
        <div className="section section-image notSelected" id={sectionId}>
            <div className="container company-wrapper" style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="row w-100 content-wrapper">
                    <div className="company-message">
                        <VisibilitySensor>
                        {({ isVisible }) => {
                            let sect = document.getElementById(sectionId);
                            if (sect) {
                                if (isVisible) {
                                    sect.classList.remove("notSelected");
                                    sect.classList.add("selected");
                                    if (direction == "right") {
                                        sect.classList.remove("left")
                                        sect.classList.add("normal")
                                    }

                                    else {
                                        sect.classList.add("left")
                                        sect.classList.remove("normal")
                                    }

                                }
                                else {
                                    sect.classList.add("notSelected");
                                    sect.classList.remove("selected");
                                    if (direction == "right") {
                                        sect.classList.add("left")
                                        sect.classList.remove("normal")
                                    }
                                    else {
                                        sect.classList.remove("left")
                                        sect.classList.add("normal")
                                    }
                                }

                            }
                            return (
                                <div className={`col-md-5 col-sm-12 m-0 p-0 ${isVisible ? "fadeIn enter" : "fadeIn"}`}>
                                    <div className="section-logo">
                                        <img alt={alt} src={logo} />
                                        <h2>{subTitle}</h2>
                                    </div>
            
                                    <ListView
                                        title={title}
                                        list={list}
                                    />
                                    <p></p>
                                    <a className="visit-site" target="_blank" href={visitLink}>visite o site</a>
                                </div>
                            )}}
                        </VisibilitySensor>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompaniesSection;